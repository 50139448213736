import LivingMap, {
  FilterKing,
  GlobalFilters,
  LivingMapPlugin,
  Utils,
} from "@livingmap/core-mapping";
import { Feature } from "geojson";
import mapboxgl from "mapbox-gl";

import {
  RouteQueryResponse,
  RouteMilestoneFeature,
} from "../../../redux/services/config";

import { EMPTY_DATA_SOURCE } from "./position-control";

import { EventTypes, LayerIds, SourceIds } from "./types";

const {
  createGeoJSONFeature,
  createGeoJSONFeatureCollection,
  createGeoJSONGeometryPoint,
} = Utils;

interface RoutePointProperties {
  route_icon: string;
  floor_id?: number;
}

interface Coordinate {
  x: number;
  y: number;
  floorId?: number;
}

// A larger divisor will result in a slower animation
const ROUTE_ANIMATION_STEP_DIVISOR = 100;
// A larger multiplier will result in a longer gradient on the route line on long routes
const ROUTE_ANIMATION_STEP_MULTIPLIER = 20;
// Used to ensure that steps aren't too quick on long routes
const ROUTE_ANIMATION_MAX_STEP = 5;

class RoutingPlugin extends LivingMapPlugin {
  private filterInstance: FilterKing;
  private zoomLevel: number = 0;
  private routeLinePadding: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  } = { top: 0, bottom: 0, left: 0, right: 0 };
  private animationFrameInstance: number = 0;

  public constructor(id: string, LMMap: LivingMap, zoomLevel: number) {
    super(id, LMMap);
    this.filterInstance = LMMap.getFilterKing();
    this.zoomLevel = zoomLevel;
  }

  public activate(): void {
    this.setRouteVisualisation();
  }

  public deactivate(): void {
    this.clear();
  }

  private setRouteVisualisation(): void {
    // display the routing line with blue and grey
    this.filterInstance.updateLocalFilter(LayerIds.ROUTE_LINE_OTHER_LAYER, {
      globalExclusions: [GlobalFilters.FLOOR],
    });
    this.filterInstance.updateLocalFilter(
      `${LayerIds.ROUTE_LINE_OTHER_LAYER}-base`,
      { globalExclusions: [GlobalFilters.FLOOR] },
    );
  }

  public renderRoute(
    routeGeoJson: RouteQueryResponse["segments"][number]["routeGeoJson"],
    routeMilestones: RouteMilestoneFeature[],
  ) {
    const origin = routeGeoJson[0];
    const originCoordinates: Coordinate = {
      x: origin.geometry.coordinates[0][0],
      y: origin.geometry.coordinates[0][1],
      floorId: origin.properties.floorId,
    };

    const bounds = this.parseAndDisplayRoute(
      routeGeoJson,
      routeMilestones,
      originCoordinates,
    );

    this.handleMapDisplay(bounds);
    this.handleRouteAnimation(routeGeoJson);
  }

  public clear(): void {
    cancelAnimationFrame(this.animationFrameInstance);

    const mapInstance = this.LMMap.getMapboxMap();
    const iconSources = mapInstance.getSource(
      SourceIds.ROUTE_ICON_SOURCE,
    ) as mapboxgl.GeoJSONSource;

    if (iconSources) iconSources.setData(EMPTY_DATA_SOURCE);

    const routeLine = mapInstance.getSource(
      SourceIds.ROUTE_LINE_SOURCE,
    ) as mapboxgl.GeoJSONSource;
    if (routeLine) routeLine.setData(EMPTY_DATA_SOURCE);

    const routeOtherLine = mapInstance.getSource(
      SourceIds.ROUTE_LINE_OTHER_SOURCE,
    ) as mapboxgl.GeoJSONSource;
    if (routeOtherLine) routeOtherLine.setData(EMPTY_DATA_SOURCE);

    const routeAnimatedLine = mapInstance.getSource(
      SourceIds.ROUTE_LINE_ANIMATED_SOURCE,
    ) as mapboxgl.GeoJSONSource;
    if (routeAnimatedLine) routeAnimatedLine.setData(EMPTY_DATA_SOURCE);
  }

  private generatePoint(point: Coordinate, icon: string): Feature {
    const startPointGeometry = createGeoJSONGeometryPoint([point.x, point.y]);
    const featureProperties: RoutePointProperties = { route_icon: icon };

    if (point.floorId !== null) {
      featureProperties.floor_id = point.floorId;
    }

    const feature = createGeoJSONFeature(featureProperties, startPointGeometry);

    return feature;
  }

  private parseAndDisplayRoute(
    geoJsonData: RouteQueryResponse["segments"][number]["routeGeoJson"],
    routeMilestones: RouteMilestoneFeature[],
    from: Coordinate,
  ): mapboxgl.LngLatBoundsLike {
    const mapInstance = this.LMMap.getMapboxMap();

    const iconSources = mapInstance.getSource(
      SourceIds.ROUTE_ICON_SOURCE,
    ) as mapboxgl.GeoJSONSource;
    const routeLine = mapInstance.getSource(
      SourceIds.ROUTE_LINE_SOURCE,
    ) as mapboxgl.GeoJSONSource;
    const routeOtherLine = mapInstance.getSource(
      SourceIds.ROUTE_LINE_OTHER_SOURCE,
    ) as mapboxgl.GeoJSONSource;

    const features: Feature[] = [];
    routeMilestones.forEach((routeMilestone) => {
      const coordinate: Coordinate = {
        x: routeMilestone.geometry.coordinates[0],
        y: routeMilestone.geometry.coordinates[1],
      };

      if (routeMilestone.properties.originId) {
        coordinate.floorId = routeMilestone.properties.originId;
      }

      features.push(
        this.generatePoint(coordinate, routeMilestone.properties.mapIcon),
      );
    });

    const startFeatureCollection = createGeoJSONFeatureCollection(features);
    iconSources.setData(startFeatureCollection);

    const bounds = new mapboxgl.LngLatBounds();

    const startLgnLat = new mapboxgl.LngLat(from.x, from.y);
    bounds.extend(startLgnLat);

    const endLngLat = new mapboxgl.LngLat(from.x, from.y);
    bounds.extend(endLngLat);

    const geoJsonDataWithFloor = geoJsonData.map((feature) => {
      feature.geometry.coordinates.forEach((lngLatPair) => {
        bounds.extend(lngLatPair);
      });

      return {
        ...feature,
        properties: {
          ...feature.properties,
          floor_id: feature.properties.floorId,
        },
      };
    });

    routeLine.setData({
      type: "FeatureCollection",
      features: geoJsonDataWithFloor,
    });
    routeOtherLine.setData({
      type: "FeatureCollection",
      features: geoJsonDataWithFloor,
    });

    const exportBounds: mapboxgl.LngLatBoundsLike = [
      bounds.getSouthWest(),
      bounds.getNorthEast(),
    ];

    return exportBounds;
  }

  private handleRouteAnimation(
    geoJsonData: RouteQueryResponse["segments"][number]["routeGeoJson"],
  ) {
    const mapInstance = this.LMMap.getMapboxMap();

    const animatedRouteLine = mapInstance.getSource(
      SourceIds.ROUTE_LINE_ANIMATED_SOURCE,
    ) as mapboxgl.GeoJSONSource;
    if (!animatedRouteLine) return;

    const featureCoordinates: [number, number][] = [];
    geoJsonData.forEach((feature) =>
      featureCoordinates.push(...feature.geometry.coordinates),
    );

    // The bigger the step size the faster the animation
    // This is to ensure that long routes are not animated too slowly and short routes are not animated too quickly
    const step = Math.min(
      featureCoordinates.length / ROUTE_ANIMATION_STEP_DIVISOR,
      ROUTE_ANIMATION_MAX_STEP,
    );

    // Position in the route
    let pos = 0;

    const animateRoute = () => {
      if (pos < featureCoordinates.length) {
        const feature = createGeoJSONFeature(
          {},
          {
            coordinates: featureCoordinates.slice(
              pos,
              pos + step * ROUTE_ANIMATION_STEP_MULTIPLIER,
            ),
            type: "LineString",
          },
        );
        animatedRouteLine.setData(createGeoJSONFeatureCollection([feature]));
        pos += step;
      } else {
        animatedRouteLine.setData(createGeoJSONFeatureCollection([]));
        pos = 0;
      }

      this.animationFrameInstance = requestAnimationFrame(animateRoute);
    };

    animateRoute();
  }

  public setRouteLinePadding(padding: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  }): void {
    this.routeLinePadding = padding;
  }

  public handleMapDisplay(bounds: mapboxgl.LngLatBoundsLike): void {
    const mapInstance = this.LMMap.getMapboxMap();
    const bearing = mapInstance.getBearing();

    try {
      mapInstance.fitBounds(bounds, {
        maxZoom: this.zoomLevel,
        padding: this.routeLinePadding,
        bearing,
      });

      this.LMMap.emit(EventTypes.ROUTE_DISPLAYED);
    } catch (e) {
      console.error(e);
    }
  }

  public handleFitMapToCoordinate(coords: mapboxgl.LngLatBoundsLike): void {
    const mapInstance = this.LMMap.getMapboxMap();
    const bearing = mapInstance.getBearing();

    const bounds = new mapboxgl.LngLatBounds();

    bounds.extend(coords);

    try {
      mapInstance.fitBounds(bounds, {
        maxZoom: this.zoomLevel,
        padding: this.routeLinePadding,
        bearing,
      });
    } catch (e) {
      console.error(e);
    }
  }
}

export default RoutingPlugin;
